import React from "react"
import "../styles/global.css"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/global.css"

const Conseils = () => (
  <div className="conseil-backround-container">
    <div className="philosophy">
      <div className="conseils-center">
        <h2>Mes conseils</h2>
        <p className="conseil-text">
          Pour votre bien être, rien ne remplace une vie saine.{<br />}
          Retrouver tous mes conseils sur mon blog
        </p>
        <span className="boutton-vert">
          <Link to="/blog" style={{ color: "white" }}>
            Tous mes conseils
          </Link>
        </span>
      </div>
    </div>
    <StaticImage
      className="image-conseils"
      width={200}
      layout="fixed"
      src="../images/DSC_6185.jpg"
      alt="portrait Olivier Ortonne"
    />
  </div>
)

export default Conseils
