import React from "react"
import { graphql } from "gatsby"
import { Container } from "react-bootstrap"
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Skill from "../components/skills"
import "../styles/global.css"
import Conseils from "../components/conseils"

const ProfilPage = ({ data }) => (
  <Layout>
    <Seo title="Profil" />

    <Container className="container">
      <Img
        fixed={data.file.childImageSharp.fixed}
        style={{ maxHeight: 500, width: "100%", objectFit: "cover" }}
      />
      <h1>Compétences</h1>
      <div className="container-wrap">
        <Skill name={"Kinésithérapeute D.E"} />
        <Skill name={"Ostéopathie D.O"} />
        <Skill name={"Manipulations thoraciques"} />
        <Skill name={"Somato émotionnel"} />
        <Skill name={"Manipulations viscéral"} />
        <Skill name={"Enseignant"} />
      </div>
      <h2>Ma philosophie</h2>
      <div className="philosophy">
        <StaticImage
          width={300}
          height={300}
          layout="fixed"
          src="../images/overboard.jpg"
          alt="portrait Olivier Ortonne"
        />
        <p className="philosophiy-text">
          Le cabinet paramédical de Puy besseau à Cusset propose une approche
          pluridisciplinaire, permettant d'accompagner chaque patient au mieux
          dans son cheminement vers la Santé. Le soins ostéopathique permet au
          patient d'être au cœur de son traitement, de son ressenti, de ses
          expériences et ses émotions. Quelque soit son âge, son histoire, son
          contexte familial ou professionnel, chaque individu accumule des
          traumatismes qui passent parfois inaperçu, et qui ressurgissent tôt ou
          tard. Cette approche en ostéopathie douce et inductive, s'adapte au
          plus grand nombre, et pour traiter de nombreux symptômes, souvent sans
          signes pathologiques aux examens. Le traitement en ostéopathie prend
          en considération l être humain dans son intégralité, à la fois
          physique et émotionnel, pour permettre au patient de se retrouver avec
          lui-même, et continuer son chemin de vie vers un nouvel équilibre.
        </p>
      </div>

      <Conseils />
      <div style={{ height: "30px" }}></div>
    </Container>
  </Layout>
)

export default ProfilPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "Portrait_Olivier.jpg" }) {
      childImageSharp {
        fixed(cropFocus: CENTER, width: 720) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
