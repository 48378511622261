import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import "../styles/skills.css"

const Skill = ({ name }) => (
  <div className="fond">
    <FontAwesomeIcon icon={faCheckCircle} size="1x" />
    <span className="text">{name}</span>
  </div>
)

export default Skill
